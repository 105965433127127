<template>
    <div class="header">
        <div class="content">
            <div class="nav_wrap">
                <div class="left">
                    <div class="logo">
                        <img src="~@/assets/common/logo2.png" alt="">
                    </div>
                    <router-link to="/index" class="nav_list">网站首页</router-link>
                    <router-link to="/introduction" class="nav_list" :class="{'active':active == 'introduction'}">产品简介</router-link>
                    <router-link to="/recruitment" class="nav_list" :class="{'active':active == 'recruitment'}">诚聘英才</router-link>
                    <router-link to="/about" class="nav_list" :class="{'active':active == 'about'}">关于我们</router-link>
                    <router-link to="/app_download" class="nav_list" :class="{'active':active == 'app_download'}">APP下载</router-link>
                </div>
                <!-- <div class="right">
                    <button>登录</button>
                    <button>注册</button>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            active:''
        }
    },
    created(){
        this.active = this.$route.name
    }
}
</script>

<style lang="less">
    .header{
        background:#fff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
        .content{
            width: @max_width;
            margin: 0 auto;
            .nav_wrap{
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 86px;
                .left{
                    display: flex;
                    align-items: center;
                    .nav_list{
                        margin-left: 30px;
                        color: #7B7E86;
                        transition: all 0.3s ease;
                        cursor: pointer;
                        &:hover{
                            transition: all 0.3s ease;
                            color: #01B671;
                        }
                        &.active{
                            color: #01B671;
                        }
                    }
                }
                .right{
                    display: flex;
                    padding: 20px 0;
                    button{
                        .btn_style();
                    }
                }
            }
        }
    }
</style>