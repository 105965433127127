<template>
  <div class="footer">
    <div class="content">
      <div class="left">
        <span>版权所有 : <a href="https://beian.miit.gov.cn/" target="_blank">© 贵州欣享云智能科技有限公司</a></span>
        <span class="ba" >备案号 :<a href="https://beian.miit.gov.cn/" target="_blank"> 黔ICP备2020010037号-2</a></span>
      </div>
      <div class="right">
        <img src="~@/assets/common/logo2.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goAQ() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
  },
};
</script>

<style lang="less">
.footer {
  padding: 10px;
  background: #fff;
  color: #7b7e86;
  .content {
    width: @max_width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      span:last-child {
        padding-left: 50px;
      }
      .ba {
        cursor: pointer;
      }
    }
  }
}
</style>