<template>
    <div class="introduction">
        <Header></Header>
        <div class="in_content">
            <div class="content">
                <div class="left">
                    <el-carousel trigger="click" style="height:773px">
                        <el-carousel-item v-for="item in 4" :key="item">
                            <img src="~@/assets/common/banner.png" alt="">
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right">
                    <div class="_wrap" :class="{'rightMaxHeight':!isShowMore}">
                        <div class="title">享印来云打印终端设备（机型：XYL-PR-D101）</div>
                        <div class="title2">功能及相关技术参数</div>
                        <div class="params_wrap">
                            <div class="list">
                                <div class="p_name">打印</div>
                                <div class="p_con">
                                    <div class="pc_list">
                                        <span class="pcl_name">打印尺寸</span>
                                        <span class="pcl_text">A4</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">单双面打印</span>
                                        <span class="pcl_text">支持</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">打印颜色</span>
                                        <span class="pcl_text">黑白、彩色</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">打印页范围</span>
                                        <span class="pcl_text">支持</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">打印来源</span>
                                        <span class="pcl_text">手机上传 / 手机扫描 / 手机拍照</span>
                                    </div>
                                </div>
                            </div>
                            <div class="list">
                                <div class="p_name">照片</div>
                                <div class="p_con">
                                    <div class="pc_list">
                                        <span class="pcl_name">打印尺寸</span>
                                        <span class="pcl_text">6 寸 / 2 寸 / 小 2 寸 / 1 寸</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">打印方式</span>
                                        <span class="pcl_text">手机传图打印</span>
                                    </div>
                                </div>
                            </div>
                            <div class="list">
                                <div class="p_name">扫 描</div>
                                <div class="p_con">
                                    <div class="pc_list">
                                        <span class="pcl_name">尺寸</span>
                                        <span class="pcl_text">A4 / B5 / 卡片</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">自动裁边</span>
                                        <span class="pcl_text">支持</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">证件照合并页</span>
                                        <span class="pcl_text">支持 / 驾驶证照 / 结婚证照</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">下载扫描</span>
                                        <span class="pcl_text">支持</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">扫描后打印</span>
                                        <span class="pcl_text">支持</span>
                                    </div>
                                </div>
                            </div>
                            <div class="list">
                                <div class="p_name">智能化运维</div>
                                <div class="p_con">
                                    <div class="pc_list">
                                        <span class="pcl_name">纸量及墨量提示</span>
                                        <span class="pcl_text">支持</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">缺纸报警</span>
                                        <span class="pcl_text">支持</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">故障报警</span>
                                        <span class="pcl_text">支持</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">在线监测</span>
                                        <span class="pcl_text">支持</span>
                                    </div>
                                </div>
                            </div>
                            <div class="list">
                                <div class="p_name">投放场所</div>
                                <div class="p_con">
                                    <div class="pc_list">
                                        <span class="pcl_name">（01）</span>
                                        <span class="pcl_text">中国各类型教学校区</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">（02）</span>
                                        <span class="pcl_text">中国各类型旅游景区</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">（03）</span>
                                        <span class="pcl_text">中国各类型政企办公大厅</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">（04）</span>
                                        <span class="pcl_text">中国各类型交通办公区域</span>
                                    </div>
                                </div>
                            </div>
                            <div class="list">
                                <div class="p_name">网络</div>
                                <div class="p_con">
                                    <div class="pc_list">
                                        <span class="pcl_name">4G网络</span>
                                        <span class="pcl_text">支持</span>
                                    </div>
                                </div>
                            </div>
                            <div class="list">
                                <div class="p_name">重量</div>
                                <div class="p_con">
                                    <div class="pc_list">
                                        <span class="pcl_name">主体（Kg）</span>
                                        <span class="pcl_text">约250</span>
                                    </div>
                                </div>
                            </div>
                            <div class="list">
                                <div class="p_name">功耗</div>
                                <div class="p_con">
                                    <div class="pc_list">
                                        <span class="pcl_name">最大功耗</span>
                                        <span class="pcl_text">1000</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">一般情况（W）</span>
                                        <span class="pcl_text">150</span>
                                    </div>
                                </div>
                            </div>
                            <div class="list">
                                <div class="p_name">机器规格</div>
                                <div class="p_con">
                                    <div class="pc_list">
                                        <span class="pcl_name">外观尺寸（mm）</span>
                                        <span class="pcl_text">1745 x 838 x 530</span>
                                    </div>
                                </div>
                            </div>
                            <div class="list">
                                <div class="p_name">主要部件</div>
                                <div class="p_con">
                                    <div class="pc_list">
                                        <span class="pcl_name">（01）</span>
                                        <span class="pcl_text">爱普生5290A</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">（02）</span>
                                        <span class="pcl_text">DNP相片打印机</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">（03）</span>
                                        <span class="pcl_text">装订机JH-50</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">（04）</span>
                                        <span class="pcl_text">J1900 主机</span>
                                    </div>
                                    <div class="pc_list">
                                        <span class="pcl_name">（05）</span>
                                        <span class="pcl_text">32 寸显示器 </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn" v-if="!isShowMore" @click="isShowMore = true">查看更多</div>
                </div>
            </div>
            <!-- 应用简介 -->
            <div class="synopsis">
                <div class="title">应用简介：</div>
                <p>享印来云打印APP是由贵州欣享云智能科技有限公司旗下推出的智能云打印平台，可以为用户提供方便快捷的打印、扫描、照相等服务。</p>
                <p>APP主要功能有：</p>
                <p><b>文档打印：</b></p>
                <p>快速上传手机文件APP应用，然后搜索附近的设备进行打印，打印支持Word，Excel，PowerPoint等应用文档，以及TXT、PDF、JPG、PNG等常用格式；</p>
                <p><b>文档扫描：</b></p>
                <p>APP端提供普通文档扫描、身份证扫描并自动合成PDF然后进行打印；</p>
                <p><b>证件照：</b></p>
                <p>可以通过APP拍摄证件照，并提供智能自动换底（支持红、蓝、白三底），支持自动美颜等功能，提供1寸照、2寸照、6寸照、结婚照、驾驶证照等规格以供选择；并提供证件夹，保存常用规格照片；</p>
                <p><b>自由玩：</b></p>
                <p>在APP端提供新奇的人工智能照片玩法，包括：换背景、加文字、变年龄、高级美颜（美白、大眼、瘦脸）、照片卡通化等多种玩法，保存后可直接在享印来终端进行打印。</p>
                <p><b>文档分享：</b></p>
                <p>可以将常用的文档基于地理位置和他人共享，促进信息共享。当他人引用文档后，分享者将获得一定积分。</p>
                <p><b>积分兑换：</b></p>
                <p>消费者获得的积分，可以用于兑换打印支付，在享印来打印终端上打印文档。</p>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
    data(){
        return{
            isShowMore:false
        }
    },
    components:{
        Header,
        Footer
    }
}
</script>

<style lang="less">
    .introduction{
        .in_content{
            padding: 20px 0;
            .synopsis{
                max-width: @max_width;
                margin: 0 auto;
                .title{
                    font-size: 16px;
                    font-weight: bold;
                    margin-top: 30px;
                    padding: 10px;
                    background: #F6F6F6;
                }
                p{
                    padding:10px 10px 0;
                    color: #384555;
                    b{
                        font-size: 15px;
                    }
                }
            }
            .content{
                max-width: @max_width;
                margin: 0 auto;
                display: flex;
                .left{
                    width: 532px;
                }
                .right{
                    width: 0;
                    flex: 1;
                    padding-left: 50px;
                    .rightMaxHeight{
                        height: 773px;
                        overflow: auto;
                    }
                    .title{
                        font-size: 28px;
                        font-weight: bold;
                    }
                    .title2{
                        font-size: 16px;
                        font-weight: bold;
                        margin-top: 30px;
                        padding: 10px;
                        background: #F6F6F6;
                    }
                    .btn{
                        font-size: 16px;
                        padding: 10px;
                        background: #F6F6F6;
                        text-align: center;
                        cursor: pointer;
                    }
                    .params_wrap{
                        padding:0px 0 20px;
                        .list{
                            display: flex;
                            color: #384555;
                            padding:10px 20px;
                            border-bottom: 1px solid #F6F6F6;
                            &:first-child{
                                border-top: 1px solid #F6F6F6;
                            }
                            .p_name{
                                padding: 10px 0;
                                width: 100px;
                            }
                            .p_con{
                                padding-left: 50px;
                                .pc_list{
                                    padding: 10px 0;
                                    .pcl_name,.pcl_text{
                                        display: inline-block;
                                    }
                                    .pcl_name{
                                        width: 150px;
                                        text-align: right;
                                    }
                                    .pcl_text{
                                        padding-left: 50px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .el-carousel__container{
            height:773px
        }
        .el-carousel__item{
            height:773px
        }
        .el-carousel__button{
            width: 12px;
            height: 12px;
            background: #c6c6c6;
            border: 1px solid #c6c6c6;
            border-radius: 50%;
        }
    }
</style>